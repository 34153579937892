import { FilterType } from 'shared/components/Table/enums';
import { HeaderInfo } from 'shared/components/Table/types';

export const employeesTableHeaders: HeaderInfo[] = [
  {
    fieldKey: 'full_name',
    displayName: 'Full Name',
    sortable: true,
    // filterType: FilterType.text,
  },
  {
    fieldKey: 'status',
    displayName: 'Status',
    sortable: true,
  },
  {
    fieldKey: 'role_id',
    displayName: 'Role',
    sortable: true,
  },
];
