import { Employee } from 'interfaces/employee';
import { EmployeeServerEntity } from './interface';

export const convertEmployeeFromResponse = ({
  employee_id,
  user_id,
  role_id,
  email,
  permissions: { modules },
  information: { first_name, last_name, job_title, profile_image },
}: EmployeeServerEntity): Employee => ({
  email,
  user_id,
  role_id,
  employee_id,
  full_name: `${first_name || ''} ${last_name || ''}`,
  job_title,
  profile_image,
  modules: Object.keys(modules),
});
